import {createStore, applyMiddleware} from 'redux';
import rootReducers from './reducer';
import thunk from 'redux-thunk';
// import logger from 'redux-logger';

let middleware = [thunk];

  middleware = [...middleware];

    const store  = createStore(rootReducers, undefined,applyMiddleware(...middleware));


    export default store;
