import React from 'react';
import { Route, HashRouter } from 'react-router-dom';
import './App.css';
import store from 'timer/services/redux/store';
import { Provider } from 'react-redux';

const TimerPage = React.lazy(() => import('timer/pages'));
const WishlistPage = React.lazy(() => import('wishlist/pages'));


function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.tsx</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>

    <HashRouter>
      <React.Suspense fallback={null}>
        <Provider store={store}>
          <Route path="/timer/:id" component={TimerPage} />
        </Provider>
        <Route path="/wishlist" component={WishlistPage} />
      </React.Suspense>
    </HashRouter>

  );
}

export default App;
